// src/components/SendForm.jsx

import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import synergyLogo from './images/synergy.png';
import beraLogo from './images/berachain3.png';
import usdcimg from './images/usdc.png';
import bend from './images/bend.png';
import berps from './images/berps.png';
import bex from './images/bex.png';
import bhoney from './images/bhoney.png';
import honeywbtc from './images/honeywbtc.png';
import honeyweth from './images/honeyweth.png';
import honey from './images/honey.png';
import honeyusdc from './images/honeyusdc.png';
import honeywbera from './images/honeywbera.png';
import vdhoney from './images/vdhoney.png';
import btcLogo from './images/btc.png';
import ethLogo from './images/ethereum-logo.svg';
import bscLogo from './images/bsc-logo.svg';
import checkimg from './images/check.svg';
import metamaskLogo from './images/metamask.png';
import NetworkSelectFrom from './NetworkSelectFrom';
import NetworkSelectAssetEth from './NetworkSelectAssetEth';
import NetworkSelectAssetBsc from './NetworkSelectAssetBsc';

const ethers = require("ethers");
const { Option } = components;

const cuttingBoardContract = "0xfb81E39E3970076ab2693fA5C45A07Cc724C93c2";
const ETH_NODE_URL = 'https://rpc.tn.us-east-1.bera.flipside.services';

const abi = [
  "function queueNewCuttingBoard(address valCoinbase, uint64 startBlock, tuple(address receiver, uint96 percentageNumerator)[] weights)"
];


const SendForm = () => {
  const [perOne, setPerOne] = useState('');
  const [perOneContract, setPerOneContract] = useState('0x2E8410239bB4b099EE2d5683e3EF9d6f04E321CC');
  const [perTwo, setPerTwo] = useState('');
  const [perTwoContract, setPerTwoContract] = useState('0xe3b9B72ba027FD6c514C0e5BA075Ac9c77C23Afa');
  const [perThree, setPerThree] = useState('');
  const [perThreeContract, setPerThreeContract] = useState('0xC5Cb3459723B828B3974f7E58899249C2be3B33d');
  const [perFour, setPerFour] = useState('');
  const [perFourContract, setPerFourContract] = useState('0xAD57d7d39a487C04a44D3522b910421888Fb9C6d');
  const [perFive, setPerFive] = useState('');
  const [perFiveContract, setPerFiveContract] = useState('0x59F735C465D9972b57BCcD8339de25cA209CA044');
  const [perSix, setPerSix] = useState('');
  const [perSixContract, setPerSixContract] = useState('0x38d2A764185197484F744C0bAA06a38a2ee31876');

  const [perError, setPerError] = useState('');

  const [account, setAccount] = useState();
  const [ethBalace, setEthBalance] = useState(0);
  
  const [chainId, setChainId] = useState(1);
  const [disable, setDisable] = useState(false);
  const [sendTx, setSendTx] = useState(true);
  const [evmTx, setEvmTx] = useState('');
  const [blockNo, setBlockNo] = useState('');

  const [network, setNetwork] = useState('Ethereum');

  async function getLatestBlockNumber() {
    try {
        const response = await fetch(ETH_NODE_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                jsonrpc: '2.0',
                method: 'eth_blockNumber',
                params: [],
                id: 1
            })
        });

        const data = await response.json();

        // Parse the result to get the block number in decimal
        const blockNumberHex = data.result;
        const blockNumber = parseInt(blockNumberHex, 16);

        return blockNumber;
    } catch (error) {
        console.error("Error fetching latest block number:", error);
        return null; // Return null in case of an error
    }
  }

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      try {
        switchNetwork(80084);
      } catch (error) {
        console.error(error);
      }
    } else {
      // If MetaMask is not installed, alert the user
      alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    }
  };  



  const switchNetwork = async (targetChainId) => {
    try {
      if (!window.ethereum) throw new Error("MetaMask is not installed!");

      setChainId(targetChainId);
  
      // Convert targetChainId to hex value
      const hexChainId = ethers.toQuantity(targetChainId);
  
      // Request the current chain ID
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
  
      if (currentChainId !== hexChainId) {
        try {
          // Attempt to switch to the target network
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: hexChainId }],
          });   

        } catch (switchError) {
          console.error('Switch network error:', switchError);
          // Handle other errors or provide guidance to the user
        }
      }
    } catch (error) {
      console.error('Failed to switch networks:', error);
      // Provide feedback to the user
    }
          // Request account access
          const newAccounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());          

        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setEthBalance(parseFloat(balanceInEth));
        setAccount(newAccounts[0]);
        setSendTx(false);

  };
  
  const estimateGasCost = async (provider, transaction) => {
    const estimatedGas = await provider.estimateGas(transaction);
    const gasPrice = (await provider.getFeeData()).gasPrice;
    return (estimatedGas * gasPrice); // Total gas cost in wei
  };
      

    const handleSubmit = async (e) => {
      e.preventDefault();

      setPerError('');
      setEvmTx('');
      setSendTx(false);

      if(!perOne) setPerOne(0);
      if(!perTwo) setPerTwo(0);
      if(!perThree) setPerThree(0);
      if(!perFour) setPerFour(0);
      if(!perFive) setPerFive(0);
      if(!perSix) setPerSix(0);

      let totalPer = 0;

      if(perOne) totalPer += parseFloat(perOne);
      if(perTwo) totalPer += parseFloat(perTwo);
      if(perThree) totalPer += parseFloat(perThree);
      if(perFour) totalPer += parseFloat(perFour);
      if(perFive) totalPer += parseFloat(perFive);
      if(perSix) totalPer += parseFloat(perSix);

      // let totalPer = parseFloat(perOne) + parseFloat(perTwo) + parseFloat(perThree) + parseFloat(perFour) + parseFloat(perFive) + parseFloat(perSix);

      console.log("totalPer = ", totalPer)

      if(totalPer != 100)
      {
        setPerError('Total Percentage must be 100%');
      }
      else
      {
         // Request account access
         const newAccounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());  
      
        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setAccount(newAccounts[0]);       

        setDisable('disabled');
        console.log("sendTx = ", sendTx);

        const tperOne = parseFloat(perOne) * 100;
        const tperTwo = parseFloat(perTwo) * 100;
        const tperThree = parseFloat(perThree) * 100;
        const tperFour = parseFloat(perFour) * 100;
        const tperFive = parseFloat(perFive) * 100;
        const tperSix = parseFloat(perSix) * 100;

        const receivers = [];

        if(tperOne) {
            receivers.push({
                receiver: perOneContract,
                percentageNumerator: tperOne
            });
        }

        if(tperTwo) {
            receivers.push({
                receiver: perTwoContract,
                percentageNumerator: tperTwo
            });
        }  
        
        if(tperThree) {
          receivers.push({
              receiver: perThreeContract,
              percentageNumerator: tperThree
          });
        }  
      
        if(tperFour) {
          receivers.push({
              receiver: perFourContract,
              percentageNumerator: tperFour
          }); 
        }  
    
        if(tperFive) {
          receivers.push({
              receiver: perFiveContract,
              percentageNumerator: tperFive
          });
        }  
  
        if(tperSix) {
          receivers.push({
              receiver: perSixContract,
              percentageNumerator: tperSix
          });
        }    
        
        let bNumber = 0;        

        await getLatestBlockNumber().then(blockNumber => {
          if (blockNumber !== null) {
              console.log("Latest Block Number:", blockNumber);
              bNumber = blockNumber + 100;
          }
        }); 

        setBlockNo(bNumber);
    
          executeTx(cuttingBoardContract, account, bNumber, receivers)
            .then(() => console.log('Operation successful'))
            .catch((error) => console.error('Operation failed:', error));

      }
  
      // Implement the logic to handle form submission
    };


    async function executeTx(cuttingBoardContract, account, bNumber, receivers) {

      console.log("myTuples = ", receivers);
      
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      //var contract = new ethers.Contract(cuttingBoardContract, abi, provider);

      var contract = new ethers.Contract(cuttingBoardContract, abi, signer);

      

      //const gasEstimate = await contract.estimateGas.queueNewCuttingBoard(account, bNumber, receivers);
      //console.log('Gas Estimate:', gasEstimate.toString());

      const sendTxResponse = await contract.queueNewCuttingBoard(account, bNumber, receivers, {
          gasLimit: 1_000_000
      });


      //const sendTxResponse = await contract.queueNewCuttingBoard(account, bNumber, receivers);
      console.log('Send transaction submitted:', sendTxResponse);
      await sendTxResponse.wait();
      console.log("BSC Tx = ", sendTxResponse.hash);
      setSendTx(sendTxResponse.hash);
      setEvmTx('https://bartio.beratrail.io/tx/' + sendTxResponse.hash);
    }

  return (
    <div>  

    <div className="mainTop">
      <img src={beraLogo} /> Berachain Validator Tools from <img src={synergyLogo} /> <a href="https://www.synergynodes.com" target="_blank">Synergy Nodes</a>
      <p><b>Add / Update cutting board to the queue for Validator</b></p>
      <p><b>Supported Wallets:</b> <img src={metamaskLogo} /> Metamask</p>
    </div>

    <div className='mainBox'>
    
    <form onSubmit={handleSubmit}>
        <div className='oneBox'>
          <div className='oneLeftBottom'>
          Gauge Vaults <br />
            <div className='divFrom'>

              <div className="gleft">
                <p className="top"><img src={vdhoney} /> VDHONEY</p>
                <p className="bottom"><img src={bend} /> BEND</p>
              </div> 
              <div className='gright'>
                <p className="top">Incentives</p>
                <p className="bottom"><img src={honey} /> HONEY</p>
              </div>                              

            </div>

          </div>
          <div className='oneRightBottom'>
            Percentage (%) <br />
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={perOne}
                onChange={(e) => setPerOne(e.target.value)}
                placeholder='Enter Percentage'
                disabled={!account ? 'disabled' : ''}
              /> <br />
            </div>
          </div>
        </div>


        <div className='oneBox'>
          <div className='oneLeftBottom'>
            <div className='divFrom'>

            <div className="gleft">
              <p className="top"><img src={honeyusdc} /> HONEY-USDC</p>
              <p className="bottom"><img src={bex} /> BEX</p>
            </div> 
            <div className='gright'>
            <p className="top">Incentives</p>
            <p className="bottom"><img src={usdcimg} /> USDC</p>
            </div>                              

          </div>

          </div>
          <div className='oneRightBottom'>
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={perTwo}
                onChange={(e) => setPerTwo(e.target.value)}
                placeholder='Enter Percentage'
                disabled={!account ? 'disabled' : ''}
              /> <br />
            </div>
          </div>  
        </div>



        <div className='oneBox'>
          <div className='oneLeftBottom'>
            <div className='divFrom'>

            <div className="gleft">
              <p className="top"><img src={bhoney} /> BHONEY</p>
              <p className="bottom"><img src={berps} /> BERPS</p>
            </div> 
            <div className='gright'>
                <p className="top">Incentives</p>
                <p className="bottom"><img src={honey} /> HONEY</p>
            </div>                              

          </div>

          </div>
          <div className='oneRightBottom'>
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={perThree}
                onChange={(e) => setPerThree(e.target.value)}
                placeholder='Enter Percentage'
                disabled={!account ? 'disabled' : ''}
              /> <br />
            </div>
          </div>  
        </div>



        <div className='oneBox'>
          <div className='oneLeftBottom'>
            <div className='divFrom'>

            <div className="gleft">
              <p className="top"><img src={honeywbera} /> HONEY-WBERA</p>
              <p className="bottom"><img src={bex} /> BEX</p>
            </div> 
            <div className='gright'>
                <p className="top">Incentives</p>
                <p className="bottom"><img src={honey} /> HONEY</p>
            </div>                              

          </div>

          </div>
          <div className='oneRightBottom'>
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={perFour}
                onChange={(e) => setPerFour(e.target.value)}
                placeholder='Enter Percentage'
                disabled={!account ? 'disabled' : ''}
              /> <br />
            </div>
          </div>  
        </div>



        <div className='oneBox'>
          <div className='oneLeftBottom'>
            <div className='divFrom'>

            <div className="gleft">
              <p className="top"><img src={honeywbtc} /> HONEY-WBTC</p>
              <p className="bottom"><img src={bex} /> BEX</p>
            </div> 
            <div className='gright'>
              <p className="top">Incentives</p>
              <p className="bottom"><img src={btcLogo} /> BTC</p>
            </div>                              

          </div>

          </div>
          <div className='oneRightBottom'>
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={perFive}
                onChange={(e) => setPerFive(e.target.value)}
                placeholder='Enter Percentage'
                disabled={!account ? 'disabled' : ''}
              /> <br />
            </div>
          </div>  
        </div>

        <div className='oneBox'>
          <div className='oneLeftBottom'>
            <div className='divFrom'>

            <div className="gleft">
              <p className="top"><img src={honeyweth} /> HONEY-WETH</p>
              <p className="bottom"><img src={bex} /> BEX</p>
            </div> 
            <div className='gright'>
              <p className="top">Incentives</p>
              <p className="bottom">None</p>
            </div>                              

          </div>

          </div>
          <div className='oneRightBottom'>
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={perSix}
                onChange={(e) => setPerSix(e.target.value)}
                placeholder='Enter Percentage'
                disabled={!account ? 'disabled' : ''}
              /> <br />
            </div>
          </div>  
        </div>



        { account ?
        <div className="accDetails">
          <span>Wallet:</span>
          { account ? account : '' }
          <div className="balances">
            <p><span>BERA Balance:</span> { ethBalace ? ethBalace.toFixed(4) : '' }</p>
          </div>
        </div>
        : "" }

        { perError ? <div className='error'>Total Percentage must be 100%</div> : '' }

        { !account ?
          <button type="button" onClick={connectWalletHandler}>Connect Wallet</button>
          : 
          <button type="submit" disabled={disable}>{ disable && !sendTx ? <span className="loader2" /> : "" } { !sendTx ? 'Submit' : 'Done!' }</button>
        }  
        </form>  

        
        { !evmTx ? 
          <p className='myLink'><b>NOTE: </b> New settings will take effect in 100 Blocks from the current Block number.
          </p>
        : 
          <p className='myLink'><span className="tick-mark"><img src={checkimg} /></span> Berachain Tx - <a href={evmTx} target='_blank'>Link</a> 
          <br />
          <span className="tick-mark"><img src={checkimg} /></span> New settings will take effect from the Block Number: <b>{blockNo}</b>
          </p> 
        }


    </div>

    <p className='powered'>Powered by Berachain</p>

        <br />

    </div>


  );
};

export default SendForm;
